import { Container, Grid, Typography } from '@material-ui/core'
import { Button } from 'gatsby-theme-material-ui'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import commonStyles from 'src/styles/commonStyles'
import { FirebaseContext } from 'src/utils/firebase'
import { navigation } from 'src/utils/navigation'

type Props = {
  path: string
}

export default function Home({}: Props) {
  const { user, logout } = useContext(FirebaseContext)
  const commonClasses = commonStyles()
  const [t] = useTranslation()

  return (
    <>
      <Container component="main">
        <Typography variant="h1" className={commonClasses.h1}>
          Bonjour {user?.displayName ?? user?.email}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Button variant="contained" color="primary" fullWidth to={navigation.toMerchant}>
              {t('menu.myBusiness')}
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button variant="contained" color="primary" fullWidth to={navigation.toProfile}>
              {t('menu.myProfile')}
            </Button>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Button variant="contained" color="primary" fullWidth to={navigation.toDealNew}>
              {t('menu.addDeal')}
            </Button>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Button variant="contained" color="primary" fullWidth to={navigation.toDeals}>
              {t('menu.myDeals')}
            </Button>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Button variant="contained" color="primary" fullWidth to={navigation.toSalesManagement}>
              {t('menu.salesManagement')}
            </Button>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Button variant="contained" color="primary" fullWidth to={navigation.toStatement}>
              {t('menu.statement')}
            </Button>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Button onClick={logout} variant="contained" fullWidth>
              {t('menu.logout')}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
